import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: "https://af570c650df8c0a8b2bf41eb906104b8@o4506236711272448.ingest.sentry.io/4506247999520768",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


function Main(){
  const [loader, setLoader] = React.useState(true);

  useEffect(()=>{
    //listen to window events
    window.addEventListener('domain_settings_loaded', function() {
      setLoader(false);
    });
  },[])
  if(!loader) return <></>;
  return <App />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);